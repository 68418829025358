import logo from './logo.svg';
import './App.css';
import React from "react"; 
import SearchForm from "./SearchForm/SearchForm"
import BasicRoute from './Router';




// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBnMmJAAjB1DBkxqubivnH_xjROzHKLmdo",
  authDomain: "koly-da829.firebaseapp.com",
  projectId: "koly-da829",
  storageBucket: "koly-da829.appspot.com",
  messagingSenderId: "633838444782",
  appId: "1:633838444782:web:6571ab4ab286b34f7713ff",
  measurementId: "G-7ZEQ83TTJT"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


function App() {
  return (
    // <SearchForm/>
    <BasicRoute/>
  );
}

export default App;
