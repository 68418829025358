import { Form, Input, Button, Checkbox ,Alert, Select,DatePicker, Space } from 'antd';
import React from "react"; 
import ReactDOM from 'react-dom'; // you used 'react-dom' as 'ReactDOM'
import {BrowserRouter,Routes,Route,Link,withRouter,Navigate,useNavigate} from "react-router-dom";
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import './SaveSerialNo.css'
import TopNavRight from "../TopNavRight/TopNavRight"
import moment from 'moment'
// import { Component } from 'react/cjs/react.production.min';

import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha
} from 'react-google-recaptcha-v3';


// Firebase start
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs,query, where,addDoc, getDoc, updateDoc,doc ,setDoc} from 'firebase/firestore';



// Follow this pattern to import other Firebase services
// import { } from 'firebase/<service>';

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyBnMmJAAjB1DBkxqubivnH_xjROzHKLmdo",
  authDomain: "koly-da829.firebaseapp.com",
  projectId: "koly-da829",
  storageBucket: "koly-da829.appspot.com",
  messagingSenderId: "633838444782",
  appId: "1:633838444782:web:6571ab4ab286b34f7713ff",
  measurementId: "G-7ZEQ83TTJT"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

// Get a list of cities from your database

function getLocalTime(timestamp) {     
  return new Date(parseInt(timestamp) * 1000).toLocaleString().replace(/:\d{1,2}$/,' ').substring(0,10);     
} 

const dateFormat = 'YYYY/MM/DD';

// Firebase end



var serial_no= "";
var showElem = false
var alert_type = "success"
var alert_desc = "Detailed description and advice about successful copywriting."
var alert_msg = "Success Tips"

var exipre_date =""
var platform =""
var order_no =""
var invoice_date =""
var input_success =false


function WithNavigate(props) {
  let navigate = useNavigate();
  return <SaveSerialNo {...props} navigate={navigate} />
}


// const SearchForm = () => {
class SaveSerialNo extends React.Component{


  // const [query, setQuery] = useState({
  //   name: "",
  //   email: "",
  //   platform: "",
  //   "g-recaptcha-response": ""
  // });
  constructor(props){
    super(props);
    this.state ={
      serial_no,
      showElem,
      alert_type,
      alert_msg,
      alert_desc,
      exipre_date,
      platform,
      order_no,
      invoice_date,
      input_success
    }
    this.serial_no = ""
    this.exipre_date = ""
    this.platform = ""
    this.order_no = ""
    this.invoice_date = ""
    this.input_success = false

    this.showElem = false
    this.alert_type = "success"
    this.alert_desc = "Detailed description and advice about successful copywriting."
    this.alert_msg = "Success Tips"

    this.onFinish = (values) => {
      console.log('Success:', values);
      serial_no = values["serial_no"]
      platform = values["platform"]
      order_no = values["order_no"]
      invoice_date = values["invoice_date"]
      getDeviceInfo(this,db,values);
    };
    
    this.onFinishFailed = (errorInfo) => {
      console.log('Failed:', errorInfo);
    };

    
    async function getDeviceInfo(cs,db,v) {
      serial_no = v["serial_no"]
      const q = query(collection(db, "sno"), where("serial_no", "==", serial_no));
      const querySnapshot = await getDocs(q);
      cs.exipre_date = ""
      var find_doc = null
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        find_doc = doc

        // var timestmap = doc.data()["expire_timestamp"].seconds
        // cs.exipre_date = getLocalTime(timestmap)
      });


      if ( (find_doc!=null) && (find_doc.data()["order_no"]==null) ){

        // const docRef = await addDoc(collection(db, "sno"), {
        //   invoice_date: v["invoice_date"].format('YYYY-MM-DD'),
        //   platform: v["platform"],
        //   order_no: v["order_no"],
        //   serial_no: v["serial_no"],
        // });


        await updateDoc(
          doc(db, "sno", find_doc.id),
          {
            invoice_date: v["invoice_date"].format('YYYY-MM-DD'),
            platform: v["platform"],
            order_no: v["order_no"],
            serial_no: v["serial_no"],
          }
          
        ).then(
          function() {
            alert("序列號保固登錄成功!")
            // redirect navigate
            cs.props.navigate('/')
            cs.setState(
              {
                  input_success:true
              }
            )
          }
        );


        // find_doc.set({
        //   invoice_date: v["invoice_date"].format('YYYY-MM-DD'),
        //   platform: v["platform"],
        //   order_no: v["order_no"],
        //   serial_no: v["serial_no"],
        // }).then(function() {
        //   alert("序列號保固登錄成功!")
        //   // redirect navigate
        //   cs.props.navigate('/')
        //   cs.setState(
        //     {
        //         input_success:true
        //     }
        //   )
        // });
        

      
        // const docRef = await setDoc(find_doc, {
        //   invoice_date: v["invoice_date"].format('YYYY-MM-DD'),
        //   platform: v["platform"],
        //   order_no: v["order_no"],
        //   serial_no: v["serial_no"],
        // });


        // console.log("Document written with ID: ", docRef.id);
        // if (docRef.id!=null){
        //   alert("序列號保固登錄成功!")
          
          
        //   // redirect navigate
        //   cs.props.navigate('/')
       

        //   cs.setState(
        //     {
        //         input_success:true
        //     }
        //   )

        // }else{
        //   alert("序列號保固登錄失敗!")
        // }

      }else{
        if (find_doc==null){
          alert("該設備序列號並非在我司代理通路購買!")
        }else{
          if (find_doc.data()["order_no"]!=null){
            // var timestmap = find_doc.data()["expire_timestamp"].seconds
            // cs.exipre_date = getLocalTime(timestmap)
            alert("該序列號已完成保固登錄!")
          }else{
            alert("異常，請諮詢客服!")
          }
        }
        
       
      }
     
      // return ;
    }

    


  }

  render(){
  if (this.state.input_success){
    // return <a href="/"></a>
  }else{

  }
  return (

   

    <GoogleReCaptchaProvider reCaptchaKey="6LfDcVUeAAAAAGJcQzzy2ZM2Ik2ph0RAJejnklDc">
    <TopNavRight/>
    
    <Form
      name="basic"
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 8,
      }}
      initialValues={{
        remember: true,
      }}
      onFinish={this.onFinish}
      onFinishFailed={this.onFinishFailed}
      autoComplete="off"
    >
       <Form.Item
        wrapperCol={{
          offset: 11,
          span: 16,
        }}
      >

      </Form.Item>

      <Form.Item
        label="產品序列號"
        name="serial_no"
        // name = {this.serial_no}
        rules={[
          {
            
            required: true,
            message: '請輸入產品序列號!',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item label="購買平台" name="platform" >
        <Select>
          <Select.Option value="inbodyhome">InBodyHome官網</Select.Option>
          <Select.Option value="koly">闊立Koly官網</Select.Option>
          <Select.Option value="1shop">1shop一頁式銷售</Select.Option>
          <Select.Option value="pchome">PChome</Select.Option>
          <Select.Option value="shopee">蝦皮</Select.Option>
          <Select.Option value="momo">Momo</Select.Option>
          <Select.Option value="etmall">東森ETmall</Select.Option>
          <Select.Option value="offline">線下健身房藥店等</Select.Option>
          <Select.Option value="other">其他</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item
        label="訂單號"
        name="order_no"
        // name = {this.serial_no}
      >
        <Input />
      </Form.Item>


      <Form.Item
        label="發票時間"
        name="invoice_date"
        // name = {this.serial_no}
        rules={[
          {
            
            required: true,
            message: '請輸入發票時間!',
          },
        ]}
      >
        {/* <DatePicker defaultValue={moment('2021/10/01', dateFormat)} format={dateFormat} /> */}
        <DatePicker defaultValue="" format={dateFormat} /> 
      </Form.Item>


      <Form.Item
        wrapperCol={{
          offset: 11,
          span: 16,
        }}
      >

          <GoogleReCaptcha
              onVerify={(token) => {
                // setQuery({ ...query, "g-recaptcha-response": token });
                console.log(token)
              }}
            />

        <Button type="primary" htmlType="搜索" size="large">
          保存
        </Button>
      </Form.Item>
    </Form>
    


    <div id="msg_container">
      {
        this.state.showElem?(
          <Alert
          message={this.state.alert_msg}
          description={this.state.alert_desc}
          type={this.state.alert_type}
          showIcon
        />
        ):null
      }
      
   

    </div>
    </GoogleReCaptchaProvider>

  );
};
};

// ReactDOM.render(<LoginForm/>, document.getElementById('root'));
// const WrappedRegistrationForm = Form.create()(RegistrationForm);
// export default SaveSerialNo;
export default WithNavigate;