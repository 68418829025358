import { Form, Input, Button, Checkbox ,Alert} from 'antd';
import React from "react"; 
import ReactDOM from 'react-dom'; // you used 'react-dom' as 'ReactDOM'
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import './SearchForm.css'
import TopNav from "../TopNav/TopNav"
import moment from 'moment'
// import { Component } from 'react/cjs/react.production.min';

import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha
} from 'react-google-recaptcha-v3';


// Firebase start
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs,query, where } from 'firebase/firestore/lite';
import { formatTimeStr } from 'antd/lib/statistic/utils';
// Follow this pattern to import other Firebase services
// import { } from 'firebase/<service>';

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyBnMmJAAjB1DBkxqubivnH_xjROzHKLmdo",
  authDomain: "koly-da829.firebaseapp.com",
  projectId: "koly-da829",
  storageBucket: "koly-da829.appspot.com",
  messagingSenderId: "633838444782",
  appId: "1:633838444782:web:6571ab4ab286b34f7713ff",
  measurementId: "G-7ZEQ83TTJT"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

// Get a list of cities from your database

function getLocalTime(timestamp) {     
  return new Date(parseInt(timestamp) * 1000).toLocaleString().replace(/:\d{1,2}$/,' ').substring(0,10);     
} 

function add0(m){return m<10?'0'+m:m }
function getOneYearTime(timestamp) {     
  timestamp = timestamp + 365*24*60*60*1000;
  // console.log(parseInt(timestamp))
  // return new Date(parseInt(timestamp) ).toLocaleString().replace(/:\d{1,2}$/,' ').substring(0,10);
  // return new Date(parseInt(timestamp) ).toLocaleString().replace(/:\d{1,2}$/,' ');     
  var now = new Date(parseInt(timestamp) )
  var y = now.getFullYear();
  var m = now.getMonth()+1;
  var d = now.getDate();
  return y+'-'+add0(m)+'-'+add0(d)+' ';
}
     
// Firebase end



var serial_no= "";
var showElem = false
var alert_type = "success"
var alert_desc = "Detailed description and advice about successful copywriting."
var alert_msg = "Success Tips"
var exipre_date =""
// const SearchForm = () => {
class SearchForm extends React.Component{

  // const [query, setQuery] = useState({
  //   name: "",
  //   email: "",
  //   platform: "",
  //   "g-recaptcha-response": ""
  // });
  constructor(props){
    super(props);
    this.state ={
      serial_no,
      showElem,
      alert_type,
      alert_msg,
      alert_desc,
      exipre_date
    }
    this.serial_no = ""
    this.exipre_date = ""
    this.showElem = false
    this.alert_type = "success"
    this.alert_desc = "Detailed description and advice about successful copywriting."
    this.alert_msg = "Success Tips"

    this.onFinish = (values) => {
      console.log('Success:', values);
      serial_no = values["username"]
      console.log(serial_no);
      getDeviceInfo(this,db,serial_no);
    };
    
    this.onFinishFailed = (errorInfo) => {
      console.log('Failed:', errorInfo);
    };

    
    async function getDeviceInfo(cs,db,serial_no) {
      const q = query(collection(db, "sno"), where("serial_no", "==", serial_no));
      const querySnapshot = await getDocs(q);
      cs.exipre_date = ""
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        // var timestmap = doc.data()["expire_timestamp"].seconds
        var t_stamp = Date.parse( doc.data()["invoice_date"])
        console.log(t_stamp)
        cs.exipre_date = getOneYearTime(t_stamp)
        // cs.exipre_date = doc.data()["expire_timestamp"]
      });
      if (cs.exipre_date==""){
        cs.setState({
          showElem:true,
          alert_type :"error",
          alert_msg : "查詢失敗",
          alert_desc : "請確認是否在InBody Home供應商闊立/妍姈公司購買",
       })
      }else{
        cs.setState({
          showElem:true,
          alert_type :"success",
          alert_msg : "查詢成功",
          alert_desc : "保修截止日期:"+cs.exipre_date +"  , 具體仍以發票時間為準",
       })
      }
     
      return ;
    }




  }

  render(){

  return (

   

    <GoogleReCaptchaProvider reCaptchaKey="6LfDcVUeAAAAAGJcQzzy2ZM2Ik2ph0RAJejnklDc">
    <TopNav/>
    
    <Form
      name="basic"
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 8,
      }}
      initialValues={{
        remember: true,
      }}
      onFinish={this.onFinish}
      onFinishFailed={this.onFinishFailed}
      autoComplete="off"
    >
       <Form.Item
        wrapperCol={{
          offset: 11,
          span: 16,
        }}
      >

      </Form.Item>

      <Form.Item
        label="產品序列號"
        name="username"
        // name = {this.serial_no}
        rules={[
          {
            
            required: true,
            message: 'Please input your username!',
          },
        ]}
      >
        <Input />
      </Form.Item>



      {/* <Form.Item
        label="Password"
        name="password"
        rules={[
          {
            required: true,
            message: 'Please input your password!',
          },
        ]}
      >
        <Input />
      </Form.Item> */}

{/* 

      <Form.Item
        wrapperCol={{
          offset: 11,
          span: 16,
        }}
      >
        <Recaptcha/>
      </Form.Item> */}




      <Form.Item
        wrapperCol={{
          offset: 11,
          span: 16,
        }}
      >

          <GoogleReCaptcha
              onVerify={(token) => {
                // setQuery({ ...query, "g-recaptcha-response": token });
                console.log(token)
              }}
            />

        <Button type="primary" htmlType="搜索" size="large">
          查詢
        </Button>
      </Form.Item>
    </Form>
    


    <div id="msg_container">
      {
        this.state.showElem?(
          <Alert
          message={this.state.alert_msg}
          description={this.state.alert_desc}
          type={this.state.alert_type}
          showIcon
        />
        ):null
      }
      
   

    </div>
    </GoogleReCaptchaProvider>

  );
};
};

// ReactDOM.render(<LoginForm/>, document.getElementById('root'));
// const WrappedRegistrationForm = Form.create()(RegistrationForm);
export default SearchForm;