import React, {ReactPropTypes,PropTypes} from 'react';
import {BrowserRouter, Routes,Route, HashRouter,Layout,Switch} from 'react-router-dom';
import SearchForm from "./SearchForm/SearchForm"
import SaveSerialNo from "./SaveSerialNo/SaveSerialNo"
import InBodyMap from "./InBodyMap/InBodyMap"



const BasicRoute = () => (
    // <BrowserRouter >
    //     <Routes>
    //         <Route path="/save"   element={<SaveSerialNo/>}/>
    //         <Route path="/"  element={<SearchForm/>}/>
    //         <Route path="/index"  element={<SearchForm/>}/>
    //     </Routes>
    // </BrowserRouter>
    <BrowserRouter>
     <Routes>
        <Route path="/map"  element={<InBodyMap/>}/>
        <Route path="/save"   element={<SaveSerialNo/>}/>
        <Route path="/" exact element={<SearchForm/>}/>
        <Route path="/index"  element={<SearchForm/>}/>
     </Routes>
   </BrowserRouter>
);


export default BasicRoute;