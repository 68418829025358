import React, { Component } from 'react';
import { GoogleMap, LoadScript,Marker,InfoWindow } from '@react-google-maps/api';


import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, getDocs,query, where } from 'firebase/firestore/lite';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import App from '../App';


const firebaseConfig = {
  apiKey: "AIzaSyBnMmJAAjB1DBkxqubivnH_xjROzHKLmdo",
  authDomain: "koly-da829.firebaseapp.com",
  projectId: "koly-da829",
  storageBucket: "koly-da829.appspot.com",
  messagingSenderId: "633838444782",
  appId: "1:633838444782:web:6571ab4ab286b34f7713ff",
  measurementId: "G-7ZEQ83TTJT"
};


const containerStyle = {
  width: '100%',//'400px',
  height: '80vh'
};


const center = {
  lat: 25.035163171610037,
  lng:  121.56349845733575
};

const shops=[]


class MyComponents extends Component {


  constructor(props){
    super(props);
    this.state ={
      shops,
    }
  this.shops = []
  }
  componentDidMount(){
    async function readMyLike(cs)  {
      const app = initializeApp(firebaseConfig);
      const db = getFirestore(app);
      cs.shops=[]
      const q = query(collection(db, "shops"));
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            console.log(`${doc.id} => ${doc.data().shop_name}`);

            var row = {
                shop_id:doc.id,
                shop_name: doc.data().shop_name, 
                lat: doc.data().lat, 
                lng: doc.data().lng, 
                position:{
                  "lat": parseFloat(doc.data().lat), 
                  "lng": parseFloat(doc.data().lng), 
                },
                link: doc.data().link, 
                // shop_img: doc.data().shop_img,
            }
            // var str=" <InfoWindow  position={{ lat:"+doc.data().lat+", lng:"+doc.data().lng+"}}>"+doc.data().shop_name +"</InfoWindow>"
            // cs.shops = cs.shops + str
            // cs.shops.push(str)
            cs.shops.push(row)
          });
       const listItems = cs.shops.map((shop) =>
      //  <div  dangerouslySetInnerHTML={{__html:shop}}></div>

          <InfoWindow
          // onLoad={onLoad}
          position={shop["position"]}  
          >
          <div >
            <a target='_blank' rel='noopener noreferer' href={shop["link"]}>
                 <h3>{shop["shop_name"]}</h3>
            </a>
          </div>
          </InfoWindow>

       );

      cs.setState({ shops : listItems })
      console.log("here")
      console.log(cs.state.shops)

     }
     readMyLike(this);
     this.shouldComponentUpdate(true)
  }
  shouldComponentUpdate(){
    return true
  }


  render() {
  

    return (
      <LoadScript
        googleMapsApiKey="AIzaSyA8tXdA2KdhFUgzS9qI7XttSPsnTZdu65Q"
      >
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={15}
        >
                  {/* <InfoWindow
                      // onLoad={onLoad}
                      position={{ lat:25.039558534996374, lng: 121.56679446894506}}  
                      >
                      <div >
                        <h3>123</h3>
                      </div>
                      </InfoWindow> */}

         

          <div>{this.state.shops} 
          </div>




          { /* Child components, such as markers, info windows, etc. */ }
          <></>
        </GoogleMap>
      </LoadScript>
    )
  }
}

export default MyComponents;
