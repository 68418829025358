import React from 'react';
import ReactDOM from 'react-dom';
import './InBodyMap.css';
import 'antd/dist/antd.css';
// import reportWebVitals from './reportWebVitals';

import { Card, Col, Row } from 'antd';

import SimpleMap from './gmap'

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, getDocs,query, where } from 'firebase/firestore/lite';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import App from '../App';


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBnMmJAAjB1DBkxqubivnH_xjROzHKLmdo",
  authDomain: "koly-da829.firebaseapp.com",
  projectId: "koly-da829",
  storageBucket: "koly-da829.appspot.com",
  messagingSenderId: "633838444782",
  appId: "1:633838444782:web:6571ab4ab286b34f7713ff",
  measurementId: "G-7ZEQ83TTJT"
};

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
// var app = App.app
// const analytics = getAnalytics(app);

var top_shops = []
var bottom_shops = []
//查看收藏



// const { Meta } = Card;

class InBodyMap extends React.Component{

  constructor(props){
    super(props);
    this.state ={
      top_shops,
      bottom_shops
    }
  this.top_shops = []
  this.bottom_shops = []



  }
  componentDidMount(){
    async function readMyLike(cs)  {
      const app = initializeApp(firebaseConfig);
      const db = getFirestore(app);
      var top_shops=[]
      var bottom_shops=[]
      const q = query(collection(db, "top_shops"));
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            console.log(`${doc.id} => ${doc.data().shop_name}`);
            var row = {
                shop_name: doc.data().shop_name, 
                shop_img: doc.data().shop_img,
            }
            if (top_shops.length<3){
              top_shops.push(row)
            }else{
              bottom_shops.push(row)
            }
          });

          const top_listItems = top_shops.map((shop) =>
          //  <div  dangerouslySetInnerHTML={{__html:shop}}></div>
              <Col span={8}>
              <Card title= {shop["shop_name"]} bordered={false}>
              <img className="gym_img" src={shop["shop_img"] }  />
              </Card>
            </Col>
           );

           const bottom_listItems = bottom_shops.map((shop) =>
          //  <div  dangerouslySetInnerHTML={{__html:shop}}></div>
              <Col span={8}>
              <Card title= {shop["shop_name"]} bordered={false}>
              <img className="gym_img" src={shop["shop_img"] }  />
              </Card>
            </Col>
           );
      
      cs.setState({ top_shops : top_listItems })
      cs.setState({ bottom_shops : bottom_listItems })

     }
     readMyLike(this);
     this.shouldComponentUpdate(true)
  }
  shouldComponentUpdate(){
    return true
  }
  render(){
    
  
  return (
    <React.StrictMode>
    {/* <App /> */}

    <div className="site-upper-card-wrapper">
    <Row gutter={16}>
     {this.state.top_shops}
    </Row>
    </div>
    <div className="site-lower-card-wrapper">
    <Row gutter={16}>
    {this.state.bottom_shops}
    </Row>

   </div>
    <SimpleMap></SimpleMap>


  </React.StrictMode>
  )
  }
}


export default InBodyMap;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
