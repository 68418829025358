import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import '../index.css';
import './TopNav.css'
import { Button, Menu, Dropdown } from 'antd';



// var btnAction = (event) => {
//     console.log("success")
// }


class TopNav extends React.Component {
    btnAction = (event) => {
        console.log("success")
   }
  
  render() {
    return (
        <div id ="top_btns_container" >
        {/* <Button id ="top_btns" type="primary" onClick={() => this.btnAction() } size="large" >查詢</Button> */}
        {/* <Button id ="top_btns"  onClick={() => this.btnAction() } size="large">保固登錄</Button> */}
        <Button id ="top_btns" type="primary" size="large" >查詢</Button>
        <Button id ="top_btns"   href="/save" size="large">保固登錄</Button>

        </div>
    );
  }
}

// ReactDOM.render(<App />, document.getElementById('container'));
export default TopNav